'use client';

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SupportAgent from '@mui/icons-material/SupportAgent';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Link from 'next/link';

const ConciergeBanner = () => {
    return (
        <Box
            sx={{
                width: '100%',
                py: { xs: 6, md: 8 },
                px: { xs: 3, sm: 4, md: 6 },
                mt: 0,
                mb: { xs: 6, md: 10 },
                position: 'relative',
                overflow: 'hidden',
                background: 'linear-gradient(135deg, #3a3a8c 0%, #2c2c54 100%)',
                borderTop: '1px solid rgba(255,255,255,0.1)',
                borderBottom: '1px solid rgba(255,255,255,0.1)',
            }}
        >
            {/* Background decorative elements */}
            <Box
                sx={{
                    position: 'absolute',
                    top: -30,
                    right: -30,
                    width: 200,
                    height: 200,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(224,177,241,0.2) 0%, rgba(224,177,241,0) 70%)',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: -50,
                    left: -50,
                    width: 250,
                    height: 250,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(224,177,241,0.1) 0%, rgba(224,177,241,0) 70%)',
                }}
            />

            <Box className="container mx-auto" sx={{ maxWidth: 1200, position: 'relative', zIndex: 2 }}>
                <Box className="flex flex-col md:flex-row items-center justify-between gap-6">
                    <Box className="flex-1">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                            <SupportAgent
                                sx={{
                                    color: '#E0B1F1',
                                    fontSize: { xs: 30, md: 40 }
                                }}
                            />
                            <Typography
                                variant="h2"
                                fontFamily="Thick"
                                sx={{
                                    fontSize: { xs: '1.75rem', sm: '2.25rem' },
                                    fontWeight: 'bold',
                                    color: 'white',
                                    textShadow: '0 2px 10px rgba(0,0,0,0.2)'
                                }}
                            >
                                Venue Concierge Service
                            </Typography>
                        </Box>

                        <Typography
                            variant="body1"
                            sx={{
                                color: 'rgba(255, 255, 255, 0.85)',
                                mb: 3,
                                maxWidth: 700,
                                fontSize: { xs: '1rem', md: '1.125rem' },
                                lineHeight: 1.6
                            }}
                        >
                            Skip the endless search. Our NYC venue experts will find your perfect space based on your
                            event needs, style preferences, and budget. Completely free, personalized recommendations
                            within 24 hours.
                        </Typography>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, mb: 4 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                <LocalActivityIcon sx={{ color: '#E0B1F1', fontSize: 20 }} />
                                <Typography sx={{ color: 'white', fontSize: '0.95rem' }}>
                                    Personalized matches
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                <LocalActivityIcon sx={{ color: '#E0B1F1', fontSize: 20 }} />
                                <Typography sx={{ color: 'white', fontSize: '0.95rem' }}>
                                    Local NYC expertise
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                <LocalActivityIcon sx={{ color: '#E0B1F1', fontSize: 20 }} />
                                <Typography sx={{ color: 'white', fontSize: '0.95rem' }}>
                                    Zero cost service
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: { xs: '100%', md: 220 }
                        }}
                    >
                        <Link href="/concierge" passHref>
                            <Button
                                className="primary-button"
                                sx={{
                                    py: 2,
                                    px: 5,
                                    fontSize: '1.125rem',
                                    width: { xs: '100%', sm: 'auto' },
                                    borderRadius: '9999px',
                                    backgroundColor: '#E0B1F1',
                                    color: '#2c2c54',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    fontFamily: 'Sans',
                                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#E0B1F1',
                                        transform: 'translateY(-3px)',
                                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.25)',
                                    },
                                    '&:active': {
                                        transform: 'translateY(0)',
                                    }
                                }}
                            >
                                Get Free Recommendations
                            </Button>
                        </Link>
                        <Typography
                            sx={{
                                mt: 2,
                                fontSize: '0.875rem',
                                color: 'rgba(255,255,255,0.6)',
                                textAlign: 'center'
                            }}
                        >
                            No obligations, just expert advice
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ConciergeBanner; 