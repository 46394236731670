'use client';

import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Skeleton } from '@mui/material';
import Link from 'next/link';
import { Venue } from '../../../shared/models';
import { FetchAllVenues } from '../lib/firebase/venues';
import LinkWithLoading from './link_with_loading';

export default function ViewableForInquiry() {
    const [venues, setVenues] = useState<Venue[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVenues = async () => {
            console.log('fetching venues');
            try {
                // Fetch all venues with a large page size to get everything at once
                const { venues: allVenues } = await FetchAllVenues(100, null, false);
                // Filter for ViewableForInquiry venues
                const viewableVenues = allVenues.filter(venue => venue.ViewableForInquiry);
                setVenues(viewableVenues);
            } catch (error) {
                console.error('Error fetching viewable venues:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVenues();
    }, []);

    if (loading) {
        return (
            <Grid container spacing={2}>
                {[...Array(6)].map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            sx={{
                                padding: 2,
                                height: '120px',
                                backgroundColor: 'rgba(255,255,255,0.05)',
                                borderRadius: 2,
                            }}
                        >
                            <Skeleton
                                variant="text"
                                width="70%"
                                height={28}
                                sx={{ bgcolor: 'rgba(255,255,255,0.08)', marginBottom: 1 }}
                            />
                            <Skeleton
                                variant="text"
                                width="90%"
                                height={20}
                                sx={{ bgcolor: 'rgba(255,255,255,0.08)' }}
                            />
                            <Skeleton
                                variant="text"
                                width="60%"
                                height={20}
                                sx={{ bgcolor: 'rgba(255,255,255,0.08)' }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <Grid container spacing={2}>
            {venues.map((venue, index) => (
                <Grid item xs={12} sm={6} md={4} key={venue.ID || index}>
                    <LinkWithLoading href={`/venue/${venue.ID}`} style={{ textDecoration: 'none' }}>
                        <Box
                            sx={{
                                padding: 2,
                                height: '120px',
                                backgroundColor: 'rgba(255,255,255,0.05)',
                                borderRadius: 2,
                                transition: 'all 0.2s ease-in-out',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(255,255,255,0.08)',
                                    transform: 'translateY(-4px)'
                                }
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontFamily='Thick'
                                color='#FFFFFF'
                                sx={{
                                    marginBottom: 0.5,
                                    fontSize: '1.1rem',
                                    lineHeight: 1.2
                                }}
                            >
                                {venue.Name}
                            </Typography>
                            <Typography
                                variant="body2"
                                fontFamily='Sans'
                                color='#AAAAAA'
                                sx={{
                                    fontSize: '0.9rem',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical'
                                }}
                            >
                                {venue.Subtitle}
                            </Typography>
                        </Box>
                    </LinkWithLoading>
                </Grid>
            ))}
        </Grid>
    );
}
