import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Chip } from '@mui/material';
import Link from 'next/link';
import { BlogPost } from '../../../shared/models';
import { format } from 'date-fns';
import LinkWithLoading from '../components/link_with_loading';
import OptimizedImage from '../components/OptimizedImage';

interface BlogCardProps {
    post: BlogPost;
    featured: boolean;
}

export default function BlogCard({ post, featured }: BlogCardProps) {
    const imageUrl = post.HeadlineImageUrl || post.Content.find(c => c.Type === 'images')?.ImageUrls?.[0] || '/img/base_banner.jpeg';
    const publishDate = format(new Date(post.PublishDate), 'MMMM d, yyyy');

    return (
        <LinkWithLoading href={`/blog/${post.ID}`} className="no-underline">
            <Card
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: featured ? 'row' : 'column'
                    },
                    height: {
                        xs: 'auto',
                        md: featured ? '400px' : 'auto'
                    },
                    bgcolor: 'rgba(255, 255, 255, 0.12)',
                    backdropFilter: 'blur(16px)',
                    borderRadius: '16px',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    overflow: 'hidden',
                    transition: 'box-shadow 0.3s ease',
                    '&:hover': {
                        boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
                    }
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: '100%',
                            md: featured ? '50%' : '100%'
                        },
                        height: {
                            xs: '200px',
                            md: featured ? '400px' : '200px'
                        },
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <OptimizedImage
                        src={imageUrl}
                        alt={post.Title}
                        fill={true}
                        style={{
                            objectFit: 'cover',
                        }}
                        quality={85}
                        sizes={featured ?
                            '(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw' :
                            '(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw'
                        }
                        priority={featured}
                        className="transition-transform duration-500 hover:scale-105"
                    />
                </Box>
                <CardContent
                    sx={{
                        width: {
                            xs: '100%',
                            md: featured ? '50%' : '100%'
                        },
                        p: {
                            xs: 3,
                            md: 4
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        background: 'linear-gradient(135deg, rgba(70,70,94,0.95) 0%, rgba(54,54,94,0.95) 100%)',
                    }}
                >
                    <Typography
                        variant="overline"
                        sx={{
                            color: 'rgba(255,255,255,0.9)',
                            letterSpacing: '0.1em',
                            fontWeight: 600,
                            fontSize: {
                                xs: '0.7rem',
                                md: '0.75rem'
                            },
                            mb: 1,
                            display: 'block'
                        }}
                    >
                        {publishDate} {post.Author && `by ${post.Author}`}
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            color: 'white',
                            fontWeight: 800,
                            fontSize: {
                                xs: '1.25rem',
                                md: featured ? '2rem' : '1.5rem'
                            },
                            mb: 2,
                            lineHeight: 1.2,
                            fontFamily: 'Cereal, sans-serif',
                        }}
                    >
                        {post.Title}
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            color: 'rgba(255,255,255,0.9)',
                            mb: post.Categories?.length > 0 ? 2 : 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: {
                                xs: 2,
                                md: featured ? 3 : 2
                            },
                            WebkitBoxOrient: 'vertical',
                            lineHeight: 1.6,
                            fontSize: {
                                xs: '0.875rem',
                                md: '1rem'
                            },
                        }}
                    >
                        {post.Excerpt}
                    </Typography>

                    {post.Categories && post.Categories.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.8,
                                mt: 'auto'
                            }}
                        >
                            {post.Categories.map((category, idx) => (
                                <Chip
                                    key={idx}
                                    label={category}
                                    size="small"
                                    sx={{
                                        bgcolor: 'rgba(224, 177, 241, 0.25)',
                                        color: 'white',
                                        fontSize: '0.7rem',
                                        height: '20px',
                                        fontWeight: 500,
                                        '&:hover': {
                                            bgcolor: 'rgba(224, 177, 241, 0.35)',
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    )}
                </CardContent>
            </Card>
        </LinkWithLoading>
    );
}
