'use client';

import React, { Suspense } from 'react';
import { Venue } from '../../../shared/models';
import dynamic from 'next/dynamic';
import { HomePageNavBar } from './homepage_navbar';

// Import Three.js components dynamically
const CoolMovingCircleBackground = dynamic(
    () => import('./moving_circle_background'),
    { ssr: false, loading: () => <div className="w-full h-full bg-[#2c2c54]" /> }
);

const HomePagePill = dynamic(
    () => import('./homepage_pill'),
    { ssr: false, loading: () => <div className="h-[200px] bg-transparent" /> }
);

interface HomePageHeroProps {
    venues: Venue[];
}
const HomePageHero = ({ venues }: HomePageHeroProps) => {
    return (
        <div className="relative h-[94vh] min-h-[740px] text-center text-white">
            <div className="absolute inset-0 bg-black z-0 w-full">
                <Suspense fallback={<div className="w-full h-full bg-[#2c2c54]" />}>
                    <CoolMovingCircleBackground />
                </Suspense>
            </div>
            <div className="absolute left-0 right-0 z-[200] h-[200px] md:h-[150px] bg-transparent">
                <Suspense fallback={<div className="h-[200px] bg-transparent" />}>
                    <HomePageNavBar />
                </Suspense>
            </div>
            <div className="flex flex-col items-start justify-start min-h-full bg-[rgba(44,44,84,0.5)] relative z-20 w-full p-[5%_5%_0]">
                <div className="h-[200px] min-h-[35vh] md:h-[150px] md:min-h-[30vh] lg:min-h-[35vh] bg-transparent" />
                <div className="justify-center h-full w-full">
                    <h1 className="mb-1 font-['Sans'] font-[200] text-white pt-4 text-[3.5rem] sm:text-[4rem] md:text-[2.5rem] lg:text-[5rem] leading-[0.8] text-left">
                        discover.
                    </h1>
                    <div className="mb-1 font-['Sans'] font-[200] text-white text-[3.5rem] sm:text-[4rem] md:text-[2.5rem] lg:text-[5rem] leading-[0.8] text-left">
                        book.
                    </div>
                    <div className="mb-1 font-['Sans'] font-[200] text-[#E0B1F1] text-[3.5rem] sm:text-[4rem] md:text-[2.5rem] lg:text-[5rem] leading-[0.8] text-left">
                        get litty.
                    </div>
                    <div className="mt-3 md:mt-4 pt-3 md:pt-4">
                        <p className="mb-1 mt-2 md:mt-3 font-['Sans'] font-[200] text-white text-[0.9rem] sm:text-[1rem] md:text-[0.9rem] lg:text-[1.1rem] max-w-full text-center [text-shadow:2px_2px_8px_rgba(0,0,0,0.8)]">
                            One stop shop for discovering and booking the perfect bar or event space. Explore real-time availability, pricing, and book instantly.
                        </p>
                        <div className="flex justify-center mt-1">
                            <Suspense fallback={<div className="h-[200px] bg-transparent" />}>
                                <HomePagePill venues={venues} />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HomePageHero.displayName = 'HomePageHero';

export default HomePageHero;
