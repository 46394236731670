'use client';

import React, { useEffect, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { BlogPost } from '../../../shared/models';
import { getAllBlogPosts } from '../lib/firebase/blog';
import BlogCard from '../blog/blog_card';

export default function BlogsList() {
    const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                console.log('[BLOGS LIST] Fetching all blog posts');
                const posts = await getAllBlogPosts();
                console.log('[BLOGS LIST] Fetched posts successfully', {
                    postsCount: posts.length,
                    postsWithImages: posts.filter(p => p.HeadlineImageUrl).length,
                    postIds: posts.map(p => p.ID)
                });

                // Log each post's image information
                posts.forEach((post, index) => {
                    console.log(`[BLOGS LIST] Post #${index + 1} image data:`, {
                        postId: post.ID,
                        title: post.Title,
                        hasHeadlineImage: !!post.HeadlineImageUrl,
                        headlineImageUrl: post.HeadlineImageUrl,
                        contentBlocksCount: post.Content?.length || 0,
                        imageBlocksCount: post.Content?.filter(block => block.Type === 'images').length || 0,
                        totalContentImages: post.Content?.reduce((count, block) => {
                            if (block.Type === 'images' && block.ImageUrls) {
                                return count + block.ImageUrls.length;
                            }
                            return count;
                        }, 0) || 0
                    });
                });

                setBlogPosts(posts);
            } catch (error) {
                console.error('[BLOGS LIST] Error fetching blog posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogPosts();
    }, []);

    if (loading) {
        console.log('[BLOGS LIST] Rendering loading skeletons');
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Skeleton className="h-[400px] bg-gray-800 animate-pulse rounded-lg" />
                </Grid>
                {[...Array(3)].map((_, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Skeleton className="h-[300px] bg-gray-800 animate-pulse rounded-lg" />
                    </Grid>
                ))}
            </Grid>
        );
    }

    console.log('[BLOGS LIST] Rendering blog posts', {
        totalPosts: blogPosts.length,
        featuredPost: blogPosts.length > 0 ? {
            id: blogPosts[0].ID,
            title: blogPosts[0].Title,
            imageUrl: blogPosts[0].HeadlineImageUrl
        } : 'No featured post',
        regularPostsCount: Math.min(blogPosts.length - 1, 9)
    });

    return (
        <Grid container spacing={3}>
            {/* Featured blog post - takes full width */}
            {blogPosts.slice(0, 1).map((post) => {
                console.log('[BLOGS LIST] Rendering featured post', {
                    id: post.ID,
                    title: post.Title,
                    imageUrl: post.HeadlineImageUrl
                });
                return (
                    <Grid item xs={12} key={post.ID}>
                        <BlogCard post={post} featured={true} />
                    </Grid>
                );
            })}

            {/* Regular blog posts - 1 per row on xs, 2 per row on md */}
            {blogPosts.slice(1, 10).map((post) => {
                console.log('[BLOGS LIST] Rendering regular post', {
                    id: post.ID,
                    title: post.Title,
                    imageUrl: post.HeadlineImageUrl
                });
                return (
                    <Grid item xs={12} md={6} key={post.ID}>
                        <BlogCard post={post} featured={false} />
                    </Grid>
                );
            })}
        </Grid>
    );
}
