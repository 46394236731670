'use client';

import { useState, useEffect } from 'react';
import {
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import HamburgerNavbarMenu from './hamburger_navbar_menu';
import { DiscoBallWithConsistentLoading } from './disco_ball';
import OptimizedImage from './OptimizedImage';

export const HomePageNavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMobileLoaded, setIsMobileLoaded] = useState(false);

  useEffect(() => {
    setIsMobileLoaded(true);
  }, []);
  if (!isMobileLoaded) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4, marginTop: 1 }}>
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4, marginTop: 1, backgroundColor: 'transparent' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: isMobile ? 0 : 4,
        marginRight: isMobile ? 0 : 4,
        backgroundColor: 'transparent',
      }}>
        {/* Left side - Larger Disco Ball and Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', backgroundColor: 'transparent' }}>
          <Box sx={{ position: 'relative', width: '200px', height: '200px', backgroundColor: 'transparent' }}>
            <DiscoBallWithConsistentLoading quality="high" size="large" />
          </Box>
          <Box sx={{
            position: 'absolute',
            zIndex: 2,
            transform: 'translateY(10px)',
            marginLeft: '90px',
            width: '180px',
            height: 'auto',
            backgroundColor: 'transparent',
          }}>
            <OptimizedImage
              src="https://firebasestorage.googleapis.com/v0/b/rentabar-3d509.appspot.com/o/img%2Flogo_with_light-1.webp?alt=media&token=6f1994ec-34f5-479e-8e02-dd909ecdeee6"
              alt="Litty Logo"
              width={180}
              height={76}
              priority={true}
              style={{
                width: '100%',
                height: 'auto'
              }}
            />
          </Box>
        </Box>

        {/* Right side - Hamburger menu */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 4, backgroundColor: 'transparent' }}>
          <HamburgerNavbarMenu />
        </Box>
      </Box>
    </Box>
  );
}
